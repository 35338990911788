<template>
  <!-- 智慧交通 -->
  <div class="content-box">
    <div class="title-box">
      <div class="title-name">智慧水务</div>
      <!-- <div class="title-p">
        未来智慧园区，融合新一代信息与通信技术，迅捷信息采集、高速信息传输、高度集中计算、智能事务处理和无所不在的服务提供能力，实现园区内及时、互动、整合的信息感知、传递和处理，以提高园区产业集聚能力、企业经济竞争力、园区可持续发展为目标的先进园区发展理念。
      </div> -->
    </div>
    <div class="naverbar">
      <div class="naverbarheader">
        <div class="navitem">
          <div
            v-for="(item, i) in navheader"
            :key="i"
            class="navertit"
            :class="{ porent: item.id == creen }"
            @click="onclick(item.id)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="naverbarbox">
        <div class="naverbarboxcen">
          <div class="naverimg"><img :src="navheader[creen].img" /></div>
          <div class="navdeas">
            <p>{{ navheader[creen].deas }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="zhihiugongshui" v-show="creen == 0">
      <div class="function">
        <div class="scenes-box">
          <!-- <h2 class="one">PLAN ADVANTAGE</h2> -->
          <h2>方案优势</h2>

          <div class="scenes-bg">
            <div>
              <div class="leftTop">
                <img
                  class="icons"
                  src="../../assets/image/wisdomWater/zhineng.png"
                  alt=""
                />
                智能
              </div>
              <div class="targetContent">
                智能远程管控、全自动运行减少运维、可视化管理
              </div>
            </div>

            <div style="margin: 0 2%">
              <div class="leftTop">
                <img
                  class="icons"
                  src="../../assets/image/wisdomWater/gaoxiao.png"
                  alt=""
                />
                高效
              </div>
              <div class="targetContent">
                实时监测、在线预警、可关联水源地水质监测数据，匹配智能加药模型，优化药耗，产水安全可靠
              </div>
            </div>
            <div>
              <div class="leftTop">
                <img
                  class="iconss"
                  src="../../assets/image/wisdomWater/fw.png"
                  alt=""
                />
                服务
              </div>
              <div class="targetContent">
                配备设备管理、能耗成本管理、报警管理、报表管理、移动 app
                等，使业主从更加精细和动态的方式管理水厂的生产运行
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="function">
        <!-- <h2 class="one">PLATFORM ARCHITECTURE</h2> -->
        <h2>平台架构</h2>
        <div class="services">
          <div class="left"></div>
        </div>
      </div>
      <div class="function">
        <!-- <h2 class="one">FUNCTION OF THE SCENE</h2> -->
        <h2>功能场景</h2>
      </div>
      <div class="scene">
        <div class="img1">
          <div class="sceneBox">
            <div class="sceneIcon icon1"></div>
            <div class="sceneTitle">水质安全</div>
            <div class="sceneContents">
              实时在线监测水质余氮、PH值、浊度等水质数据,确保供水质量。
            </div>
          </div>
        </div>

        <div class="img2">
          <div class="sceneBox">
            <div class="sceneIcon icon2"></div>
            <div class="sceneTitle">工况管理</div>
            <div class="sceneContents">
              监测设备运营,远程手动或逻辑控制水泵的启、停、阀门的开度等。
            </div>
          </div>
        </div>
        <div class="img3">
          <div class="sceneBox">
            <div class="sceneIcon icon3"></div>
            <div class="sceneTitle">园区安防</div>
            <div class="sceneContents">
              监测现场安防、设备巡检、现场环境等情况。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="paiwujiance" v-show="creen == 1">
      <div class="function">
        <div class="scenes-box">
          <!-- <h2 class="one">PLAN ADVANTAGE</h2> -->
          <h2>方案优势</h2>

          <div class="scenes-bg">
            <div>
              <div class="leftTop">
                <img
                  class="icons"
                  src="../../assets/image/monitor/glkf.png"
                  alt=""
                />
                管理规范
              </div>
              <div class="targetContent">
                通过广泛、精确且实时的智能化监测手段，做到用数据说话、
                用数据决策、用数据管理、用数据创新”，旨在逐步建立以数据为驱动的标准化管理模式，
                并建立相应的管理支撑平台。
              </div>
            </div>

            <div>
              <div class="leftTop">
                <img
                  class="icons"
                  src="../../assets/image/monitor/yxaq.png"
                  alt=""
                />
                运行安全
              </div>
              <div class="targetContent">
                对排水资产进行全面管理，建立资产设备维修台账、检测台账，通过智能监测手段，结合大数据分析对设施资产进行定期评估，预测运行风险，实现排水设施资产全生命周期管理。
              </div>
            </div>
            <div>
              <div class="leftTop">
                <img
                  class="icons"
                  src="../../assets/image/monitor/gxjc.png"
                  alt=""
                />
                高效决策
              </div>
              <div class="targetContent">
                通过建立一套涵盖“源-网-厂-
                河”监测预警体系，为排水信息化支撑平台提供更为精准的数据支撑，使得城市管理的思路、方略和决策更为科学民主，从而加快排水事业科学发展进程。
              </div>
            </div>
            <div>
              <div class="leftTop">
                <img
                  class="icons"
                  src="../../assets/image/monitor/khzq.png"
                  alt=""
                />
                规划准确
              </div>
              <div class="targetContent">
                通过选择关键点位，对现有的排水系统进行系统记录排查和实时监测，真实了解管网及设施的现状运行情况，判断是否需要提升改造，为管理部门
                和相关单位提供必要的信息和决策依据
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="function">
        <!-- <h2 class="one">PLATFORM ARCHITECTURE</h2> -->
        <h2>平台架构</h2>
        <div class="services">
          <div class="left"></div>
        </div>
      </div>
      <div class="function">
        <!-- <h2 class="one">FUNCTION OF THE SCENE</h2> -->
        <h2>功能场景</h2>
      </div>
      <div class="scene">
        <div class="img1">
          <div class="sceneBox">
            <div class="sceneIcon icon1"></div>
            <div class="sceneTitle">排污检测</div>
            <div class="sceneContents">
              确保治理设施稳定运行，监控排放水质达标且不超量排放，监测偷排漏排
            </div>
          </div>
        </div>

        <div class="img2">
          <div class="sceneBox">
            <div class="sceneIcon icon2"></div>
            <div class="sceneTitle">管网监测</div>
            <div class="sceneContents">
              管网堵塞、破损告警，分析降雨引起的入流入渗量和区域偷排漏排的情况
            </div>
          </div>
        </div>
        <div class="img3">
          <div class="sceneBox">
            <div class="sceneIcon icon3"></div>
            <div class="sceneTitle">河湖监测</div>
            <div class="sceneContents">
              实时监测水质ORP、电导率、氨氮等指标； 分析水质变化跟踪与预警；
              网格化监测管理
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="wushuchuli" v-show="creen == 2">
      <div class="function">
        <div class="scenes-box">
          <!-- <h2 class="one">PLAN ADVANTAGE</h2> -->
          <h2>方案优势</h2>

          <div class="scenes-bg">
            <div>
              <div class="leftTop">
                <img
                  class="icons"
                  src="../../assets/image/sewage/ssx.png"
                  alt=""
                />
                实时性
              </div>
              <div class="targetContent">
                系统无时延，无需轮巡就可以同步接收、处理多个/所有数据采集点的数据。可很好的满足系统对数据采集和传输实时性的要求。
              </div>
            </div>

            <div>
              <div class="leftTop">
                <img
                  class="icons"
                  src="../../assets/image/sewage/yckz.png"
                  alt=""
                />
                远程控制
              </div>
              <div class="targetContent">
                通过网络双向系统还可实现对视频设备进行远程控制，进行参数调整、转动云台等控制操作。
              </div>
            </div>
            <div>
              <div class="leftTop">
                <img
                  class="iconss"
                  src="../../assets/image/sewage/cbd.png"
                  alt=""
                />
                成本低
              </div>
              <div class="targetContent">
                由于采用现有网络或3G无线网络，只需安装好设备就可以，不需要进行专门布线，前期投资少、见效快，后期升级、维护成本低；
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="function">
        <!-- <h2 class="one">PLATFORM ARCHITECTURE</h2> -->
        <h2>平台架构</h2>
        <div class="services">
          <div class="left"></div>
        </div>
      </div>
      <div class="function">
        <!-- <h2 class="one">FUNCTION OF THE SCENE</h2> -->
        <h2>功能场景</h2>
      </div>
      <div class="scene">
        <div class="img1">
          <div class="sceneBox">
            <div class="sceneIcon icon1"></div>
            <div class="sceneTitle">资产保养</div>
            <div class="sceneContents">
              对设备和备件等资产台账管理，提供完善的各类报表。实现了对生产设备的科学化、规范化、信息化的管理，延长了设备使用寿命和提高设备的使用效率。
            </div>
          </div>
        </div>

        <div class="img2">
          <div class="sceneBox">
            <div class="sceneIcon icon2"></div>
            <div class="sceneTitle">工艺监控</div>
            <div class="sceneContents">
              本系统根据生产工艺流程将各种设备实时运行状况等信息进行图形化实时监视，生产过程中出现异常过程实时告警，从而极大的增强了生产运营的稳定性。
            </div>
          </div>
        </div>
        <div class="img3">
          <div class="sceneBox">
            <div class="sceneIcon icon3"></div>
            <div class="sceneTitle">辅助决策</div>
            <div class="sceneContents">
              本系统提供多种报表统计功能。对各阶段、各时期、各类生产运行数据可进行统计，报表分析，并以直观的图表形式形式，如历史生产数据报表，对辅助管理者的决策提供强大的支持。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      creen: 0,
      navheader: [
        {
          id: 0,
          name: "智慧供水",
          deas: "生存与生活之间也许只差一个媒介农村 ( 城乡 ) 智慧供水系统解决方案为百姓供好水",
          img: require("../../assets/image/waterImg/water.861cce22.png"),
        },
        {
          id: 1,
          name: "排污检测",
          deas: "建立一套数据驱动的标准化的管理模式，安全运行体系，排水信息化支撑平台和设施规划体系。",
          img: require("../../assets/image/waterImg/pwjc.5cfe3fbf.png"),
        },
        {
          id: 2,
          name: "污水处理",
          deas: "水体污染和富营养化危及水生态系统、水环境状况的恶化已成为建设生态文明及绿色发展的短板，我国水生态环境状况不容乐观",
          img: require("../../assets/image/waterImg/wushuibg.50ababd7.png"),
        },
      ],
      tablist: [
        {
          tit: "改进",
          dead: "改进行业运行监管方式，完善公交运行状态与数据采集体系，提升行业及企业智能调度与运营管理效率，提高城市公共交通行业动态监测、指挥协调、服务监管、异常预警和辅助决策效能；",
          icon: require("../../assets/image/parkImg/组 88.png"),
        },
        {
          tit: "服务",
          dead: "改进乘客出行信息服务方式，构建内容丰富、形式多样、及时可靠的出行信息服务体系，提高公共交通系统的出行信息服务能力。",
          icon: require("../../assets/image/parkImg/组 605.png"),
        },
        {
          tit: "防范",
          dead: "提升公交运行主动安全防范能力，基于车载智能终端与公安等部门共享数据，消除安全隐患。",
          icon: require("../../assets/image/parkImg/组 606.png"),
        },
      ],
    };
  },
  computed: {},
  watch: {},
  methods: {
    onclick(id) {
      this.creen = id;
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      console.log(scrollTop);
    },
    go(i) {
      if (i == 1) {
        this.$router.push("CloudServices");
      }
      if (i == 2) {
        this.$router.push("consumptionManagement");
      }
      if (i == 3) {
        this.$router.push("Stereosynthesis");
      }
      if (i == 4) {
        this.$router.push("assetManagement");
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
.content-box {
  width: 100%;
  overflow: hidden;
  h2 {
    font-size: 40px;
    text-align: center;
  }
  .title-box {
    width: 100%;
    color: #fff;
    height: 530px;
    padding: 0 160px;
    background: url("../../assets/image/waterImg/bcd30b6d.png") no-repeat;
    background-size: 100% 100%;
    .title-name {
      font-size: 44px;
      font-weight: normal;
      color: #ffffff;
      transform: translateY(190px);
    }
    .title-p {
      width: 55%;
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      transform: translateY(220px);
      color: #ffffff;
    }
  }
  .nh {
    background: url("../../assets/image/parkImg/nhbg.png") no-repeat;
    margin-top: 50px;
  }
  .naverbar {
    height: 410px;
    width: 100%;
    background: #fff;
    .naverbarheader {
      height: 80px;
      line-height: 80px;
      display: flex;
      justify-content: end;
      border-bottom: 1px solid #e4e4e4;
      .navitem {
        margin-right: 19%;
        display: flex;
        justify-content: end;
        border-bottom: 1px solid #e4e4e4;
        .navertit {
          color: #888888;
          font-size: 20px;
          margin-right: 60px;
          cursor: pointer;
        }
        .porent {
          color: #333333;
          font-size: 20px;
          cursor: pointer;
          position: relative;
          &::after {
            display: inline-block;
            position: absolute;
            content: "";
            width: 74px;
            height: 2px;
            background: #3651d8;
            top: 77px;
            left: 0;
            right: 0;
            margin: auto;
          }
        }
      }
    }
    .naverbarbox {
      height: 250px;
      width: 80%;
      display: flex;
      margin-left: 10%;
      margin-top: 20px;
      .naverbarboxcen {
        display: flex;
        .naverimg {
          width: 400px;
          height: 250px;
          img {
            width: 400px;
            height: 250px;
          }
        }
        .navdeas {
          width: 55%;
          margin-top: 40px;
          color: #6b6b6b;
          font-size: 18px;
          margin-left: 50px;
        }
      }
    }
  }
}

.zhihiugongshui {
  .function {
    padding: 4% 10% 0;
    background-color: #fff;

    .scenes-box {
      background-color: #fff;

      .scenes-bg {
        margin-top: 35px;
        width: 100%;

        height: 208px;
        display: flex;
        > div {
          position: relative;
          width: 32%;
          height: 100%;
          background: url("../../assets/image/wisdomWater/mbbj.png") no-repeat;
          background-size: 100% 100%;
          .leftTop {
            width: 40%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            color: #fff;
            border-bottom-right-radius: 25px;
            font-size: 20px;
            font-weight: 600;
            background-color: #c7b090;
          }
          .targetContent {
            margin: 5% 6% 0;
            color: #6b6b6b;
            line-height: 30px;
            font-size: 16px;
          }
        }
        .icons {
          width: 24px;
          height: 24px;
          vertical-align: middle;
        }
        .iconss {
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin-bottom: 3px;
        }
      }
      .scenes-text {
        width: 100%;
        height: 400px;
        text-align: center;
        background-color: #fff;
        .title {
          font-size: 28px;
          font-weight: 600;
          padding: 20px 0;
          color: #333333;
        }
        .text-box {
          display: flex;
          .text-box-item {
            width: 16%;
            font-size: 16px;
            color: #6b6b6b;
            margin-top: 60px;
            border-right: 1px solid #dfdfdf;
            p {
              padding: 10px 0;
            }
          }
        }
      }
    }
    .services {
      width: 100%;

      display: flex;
      background-color: #fff;
      .left {
        width: 100%;
        height: 985px;
        margin-top: 4%;
        background: url("../../assets/image/wisdomWater/wsjg.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .nh-text {
      font-size: 28px;
      margin: 20px 0;
      color: #6b6b6b;
    }
  }
  .scene {
    padding: 3% 18.8%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    overflow: hidden;
    background: #fff;
    .sceneBox {
      cursor: pointer;
      .sceneContents {
        padding: 10px 30px;
        color: white;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
      }

      width: 100%;
      height: 380px;
      display: flex;
      flex-direction: column;
      align-items: center;

      margin: 0 2%;
      .sceneIcon {
        margin-top: 100px;
        width: 44px;
        height: 44px;
        z-index: 99;
      }
      .sceneTitle {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin: 5px 0;
        opacity: 1;
        z-index: 99;
        box-sizing: border-box;
        padding-bottom: 10px;
        // border-bottom: 2px solid #fff;
      }
      .sceneContent {
        color: #fff;
        margin: 10px 40px;
        z-index: 99;
        display: none;
      }
    }
  }
  .img1 {
    width: 380px;
    background: url("../../assets/image/wisdomWater/szafbg.png") no-repeat;
    background-size: 100% 100%;
    // margin: 0 5%;
  }
  .img2 {
    width: 380px;
    background: url("../../assets/image/wisdomWater/gkglbg.png") no-repeat;
    background-size: 100% 100%;
    //  margin: 0 5%;
  }
  .img3 {
    width: 380px;
    background: url("../../assets/image/wisdomWater/yqafbg.png") no-repeat;
    background-size: 100% 100%;
    //  margin: 0 5%;
  }

  .icon1 {
    background: url("../../assets/image/wisdomWater/szaf.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon2 {
    background: url("../../assets/image/wisdomWater/gkgl.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon3 {
    background: url("../../assets/image/wisdomWater/yqaf.png") no-repeat;
    background-size: 100% 100%;
  }
}
.paiwujiance {
  .function {
    padding: 4% 10% 0;
    background-color: #fff;

    .scenes-box {
      background-color: #fff;

      .scenes-bg {
        margin-top: 35px;
        width: 100%;

        display: flex;
        flex-wrap: wrap;
        > div {
          position: relative;
          width: 48%;
          height: 100%;
          background: url("../../assets/image/wisdomWater/mbbj.png") no-repeat;
          background-size: 100% 100%;
          margin: 10px;
          .leftTop {
            width: 25%;
            height: 50px;
            line-height: 50px;
            padding-left: 15px;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            color: #fff;
            border-bottom-right-radius: 25px;

            background-color: #c7b090;
            text-align: left;
          }
          .targetContent {
            margin: 5% 6%;
            color: #6b6b6b;
            line-height: 30px;
            font-size: 16px;
          }
        }
        .icons {
          width: 24px;
          height: 24px;
          vertical-align: middle;
        }
        .iconss {
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin-bottom: 3px;
        }
      }
      .scenes-text {
        width: 100%;
        height: 400px;
        text-align: center;
        background-color: #fff;
        .title {
          font-size: 28px;
          font-weight: 600;
          padding: 20px 0;
          color: #333333;
        }
        .text-box {
          display: flex;
          .text-box-item {
            width: 16%;
            font-size: 16px;
            color: #6b6b6b;
            margin-top: 60px;
            border-right: 1px solid #dfdfdf;
            p {
              padding: 10px 0;
            }
          }
        }
      }
    }
    .services {
      width: 100%;

      display: flex;
      background-color: #fff;
      .left {
        width: 100%;
        // height: 985px;
        height: 1501px;
        margin-top: 4%;
        background: url("../../assets/image/monitor/paishui.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .nh-text {
      font-size: 28px;
      margin: 20px 0;
      color: #6b6b6b;
    }
  }
  .scene {
    padding: 3% 18.8%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    overflow: hidden;
    background: #fff;
    // > div {
    //   flex: 1;
    //   // &:hover {
    //   //   transform: scale(1.07, 1.07);
    //   // }
    // }
    .sceneBox {
      cursor: pointer;
      .sceneContents {
        padding: 10px 30px;
        color: white;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
      }
      // &:hover {
      //   background: rgba(0, 0, 0, 0.8);
      //   opacity: 0.8;
      //   .sceneIcon {
      //     animation: moves 0.5s linear;
      //     animation-fill-mode: forwards;
      //   }

      //   .sceneContent {
      //     display: block;
      //   }
      //   @keyframes moves {
      //     0% {
      //     }
      //     100% {
      //       margin-top: 100px;
      //     }
      //   }
      // }
      // flex: 1;
      width: 100%;
      height: 380px;
      display: flex;
      flex-direction: column;
      align-items: center;

      margin: 0 2%;
      .sceneIcon {
        margin-top: 100px;
        width: 44px;
        height: 44px;
        z-index: 99;
      }
      .sceneTitle {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin: 5px 0;
        opacity: 1;
        z-index: 99;
        box-sizing: border-box;
        padding-bottom: 10px;
        border-bottom: 2px solid #fff;
      }
      .sceneContent {
        color: #fff;
        margin: 10px 40px;
        z-index: 99;
        display: none;
      }
    }
  }
  .img1 {
    width: 380px;
    background: url("../../assets/image/monitor/pwbg.png") no-repeat;
    background-size: 100% 100%;
    // margin: 0 5%;
  }
  .img2 {
    width: 380px;
    background: url("../../assets/image/monitor/gwbg.png") no-repeat;
    background-size: 100% 100%;
    //  margin: 0 5%;
  }
  .img3 {
    width: 380px;
    background: url("../../assets/image/monitor/hhbg.png") no-repeat;
    background-size: 100% 100%;
    //  margin: 0 5%;
  }

  .icon1 {
    background: url("../../assets/image/monitor/pwIcon.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon2 {
    background: url("../../assets/image/monitor/gwIcon.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon3 {
    background: url("../../assets/image/monitor/hehuIcon.png") no-repeat;
    background-size: 100% 100%;
  }
}
.wushuchuli {
  .function {
    padding: 4% 10% 0;
    background-color: #fff;

    .scenes-box {
      background-color: #fff;

      .scenes-bg {
        margin-top: 35px;
        width: 100%;

        height: 208px;
        display: flex;
        > div {
          position: relative;
          width: 30%;
          height: 100%;
          background: url("../../assets/image/wisdomWater/mbbj.png") no-repeat;
          background-size: 100% 100%;
          margin: 0 10px;
          .leftTop {
            width: 40%;
            height: 50px;
            line-height: 50px;
            font-size: 20px;
            font-weight: 600;
            text-align: center;
            color: #fff;
            border-bottom-right-radius: 25px;

            background-color: #c7b090;
          }
          .targetContent {
            margin: 5% 6% 0;
            color: #6b6b6b;
            line-height: 30px;
            font-size: 16px;
          }
        }
        .icons {
          width: 24px;
          height: 24px;
          vertical-align: middle;
        }
        .iconss {
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin-bottom: 3px;
        }
      }
      .scenes-text {
        width: 100%;
        height: 400px;
        text-align: center;
        background-color: #fff;
        .title {
          font-size: 28px;
          font-weight: 600;
          padding: 20px 0;
          color: #333333;
        }
        .text-box {
          display: flex;
          .text-box-item {
            width: 16%;
            font-size: 16px;
            color: #6b6b6b;
            margin-top: 60px;
            border-right: 1px solid #dfdfdf;
            p {
              padding: 10px 0;
            }
          }
        }
      }
    }
    .services {
      width: 100%;

      display: flex;
      background-color: #fff;
      .left {
        width: 100%;
        height: 766px;
        margin-top: 4%;
        background: url("../../assets/image/sewage/jiagou.png") no-repeat;
        background-size: 100% 100%;
      }
    }
    .nh-text {
      font-size: 28px;
      margin: 20px 0;
      color: #6b6b6b;
    }
  }
  .scene {
    padding: 3% 18.8%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    overflow: hidden;
    background: #fff;
    .sceneBox {
      cursor: pointer;
      .sceneContents {
        padding: 10px 30px;
        color: white;
        font-size: 18px;
        line-height: 30px;
      }

      width: 100%;
      height: 380px;
      display: flex;
      flex-direction: column;
      align-items: center;

      margin: 0 2%;
      .sceneIcon {
        margin-top: 80px;
        width: 44px;
        height: 44px;
        z-index: 99;
      }
      .sceneTitle {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #ffffff;
        margin: 5px 0;
        opacity: 1;
        z-index: 99;
        box-sizing: border-box;
        padding-bottom: 10px;
        border-bottom: 1px solid #fff;
      }
      .sceneContent {
        color: #fff;
        margin: 10px 40px;
        z-index: 99;
        display: none;
      }
    }
  }
  .img1 {
    width: 380px;
    background: url("../../assets/image/sewage/zcbybg.png") no-repeat;
    background-size: 100% 100%;
    // margin: 0 5%;
  }
  .img2 {
    width: 380px;
    background: url("../../assets/image/sewage/gyjkbg.png") no-repeat;
    background-size: 100% 100%;
    //  margin: 0 5%;
  }
  .img3 {
    width: 380px;
    background: url("../../assets/image/sewage/fzjcbg.png") no-repeat;
    background-size: 100% 100%;
    //  margin: 0 5%;
  }

  .icon1 {
    background: url("../../assets/image/sewage/zcbyicon.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon2 {
    background: url("../../assets/image/sewage/gyjkicon.png") no-repeat;
    background-size: 100% 100%;
  }
  .icon3 {
    background: url("../../assets/image/sewage/fzjcicon.png") no-repeat;
    background-size: 100% 100%;
  }
}
</style>
